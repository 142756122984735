import { createStore, applyMiddleware} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import initialState from '../InitialState'

export const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
    applyMiddleware(thunk)
));