// DROPPABLE ACTIONS ---------------------

export const LIST_TO_RECEIVER = 'LIST_TO_RECEIVER';
export const RECEIVER_TO_LIST ='RECEIVER_TO_LIST';
export const TO_PATH_RECEIVER = 'TO_PATH_RECEIVER';
export const FROM_PATH_RECEIVER = 'FROM_PATH_RECEIVER';
export const RECEIVERS_TO_LISTS = 'RECEIVERS_TO_LISTS';
export const LIST_TO_LIST = 'LIST_TO_LIST';
export const ADD_SPAWNED_ITEMS_TO_LISTS = 'ADD_SPAWNED_ITEMS_TO_LISTS';
export const SPEND_ITEM = 'SPEND_ITEM';
export const ADD_DELETE_METHOD = 'ADD_DELETE_METHOD';

// STORY ACTIONS ---------------------
export const DRAG_FROM_EMBED = 'DRAG_FROM_EMBED';
export const TO_PATH_DOCK = 'TO_PATH_DOCK';
export const UPDATE_OBJECTIVE = 'UPDATE_OBJECTIVE';
export const UPDATE_POI = 'UPDATE_POI';
export const SET_TRANSITION_ACTIVE = 'SET_TRANSITION_ACTIVE';
export const SET_TRANSITION_INACTIVE = 'SET_TRANSITION_INACTIVE';
export const TOGGLE_DESCRIPTION_VISIBILITY = 'TOGGLE_DESCRIPTION_VISIBILITY';

// NETWORKING ACTIONS ---------------------
export const SET_INVALID_REQUEST_MESSAGE = 'SET_INVALID_REQUEST_MESSAGE';

// BUTTON ACTIONS ---------------------
export const BUTTON_DOWN = 'BUTTON_DOWN';
export const BUTTON_UP = 'BUTTON_UP';
export const BUTTON_ENABLE = 'BUTTON_ENABLE';
export const BUTTON_DISABLE = 'BUTTON_DISABLE';
export const NEXT_BUTTON_DOWN = 'NEXT_BUTTON_DOWN';
export const NEXT_BUTTON_UP = 'NEXT_BUTTON_UP';
export const NEXT_BUTTON_ENABLE = 'NEXT_BUTTON_ENABLE';
export const NEXT_BUTTON_DISABLE = 'NEXT_BUTTON_DISABLE';
export const NEXT_BUTTON_SHOW = 'NEXT_BUTTON_SHOW';
export const NEXT_BUTTON_HIDE = 'NEXT_BUTTON_HIDE';
export const BACK_BUTTON_DOWN = 'BACK_BUTTON_DOWN';
export const BACK_BUTTON_UP = 'BACK_BUTTON_UP';
export const BACK_BUTTON_ENABLE = 'BACK_BUTTON_ENABLE';
export const BACK_BUTTON_DISABLE = 'BACK_BUTTON_DISABLE';
export const BACK_BUTTON_SHOW = 'BACK_BUTTON_SHOW';
export const BACK_BUTTON_HIDE = 'BACK_BUTTON_HIDE';

// UPDATE MAP ---------------------
export const UPDATE_MAP = 'UPDATE_MAP';
export const SET_IS_ALT_TRUE = 'SET_IS_ALT_TRUE';

// UPDATE STAGE ---------------------
export const NEXT_STAGE = 'NEXT_STAGE'
export const TOGGLE_STAGE_TRANSITION = 'TOGGLE_STAGE_TRANSITION'

// UPDATE INTRO ---------------------
export const NEXT_SCENE = 'NEXT_SCENE'
export const BACK_SCENE = 'BACK_SCENE'
export const TOGGLE_SCENE_TRANSITION = 'TOGGLE_SCENE_TRANSITION'